import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Harrison({ styles, ...props }) {
  const Elements = useComponents()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page", { backgroundColor: "#ffffff" })}
    >
      <div className="formkit-container">
        <div className="formkit-images">
          <div className="formkit-images__item">
            <Elements.Image name="imageOne" size={{ w: 640, h: 455 }} />
          </div>
          <div className="formkit-images__item">
            <Elements.Image name="imageTwo" size={{ w: 640, h: 455 }} />
          </div>
        </div>
        <div className="formkit-card" style={styles.forElement("card")}>
          <div
            className="formkit-card__inner"
            style={styles.forElement("card_inner")}
          >
            <Elements.Image name="logo" />
            <Elements.Heading
              className="formkit-header"
              name="header"
              defaults={{ content: "Enter a catchy page headline" }}
            />
            <Elements.Region name="subheader" className="formkit-subheader">
              <Elements.Content
                defaults={{
                  content: "Provide some more detail in this subtitle"
                }}
              />
            </Elements.Region>
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Subscribe" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
          </div>
          <Elements.Content
            name="disclaimer"
            defaults={{
              content: "We respect your privacy. Unsubscribe at any time."
            }}
          />
          <Elements.BuiltWith background="card" />
        </div>
      </div>
    </Elements.LandingPage>
  )
}

Harrison.style = "landing_page"
Harrison.categories = ["Newsletter"]
Harrison.thumbnail = ""
Harrison.preview = "https://pages.convertkit.com/95f8da90f6/5f2b8acf2f"
Harrison.fields = [
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Harrison, { name: "Harrison" })
